import { obterLocalizacao } from '../../src/vanila/login_geolocation';

document.addEventListener('DOMContentLoaded', () => {
  obterLocalizacao();
  clearLocalStorage();
});

function clearLocalStorage(){
  window.localStorage.clear();
}
