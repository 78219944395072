export function obterLocalizacao() {
  if (navigator.geolocation) {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'prompt' || result.state === 'granted') {
        navigator.geolocation.getCurrentPosition(function(position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          enviarLocalizacaoParaForm(latitude, longitude);
        });
      }
    });
  } else {
    console.log('Geolocalização não suportada pelo navegador.');
  }
}

function enviarLocalizacaoParaForm(latitude, longitude) {
  $('#latitude').val(latitude);
  $('#longitude').val(longitude);
}
